<template>
  <div>
    <a-form layout="horizontal">
      <div class="w-100">
        <div class="row">
          <div class="col-md-3">
            <a-form-item>
              <template #label>
                <a-typography-title :level="5">Выберите филиал</a-typography-title>
              </template>
              <a-select
                :options="stores"
                v-model:value="selectedStore"
                @change="updateStore"
                :option-filter-prop="'label'"
                placeholder="Филиал"
                style="width: 250px"
                size="large"
                show-search
                allow-clear
              />
            </a-form-item>
          </div>
        </div>
      </div>
    </a-form>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="tableChange"
      row-key="id"
    >
      <template #title>
        <div class="pb-1 overflow-hidden w-100">
          <a-typography-title :level="3" class="pull-left">Залы</a-typography-title>
          <vb-create-hall
            @reload="reload"
          />
        </div>
      </template>
      <template #hall_id="{ record }">
        {{ record.hall_id }}
      </template>
      <template #name="{ record }">
        <div v-if="editNameId === record.id" class="row">
          <div class="col-md-10">
            <a-input @keydown.esc="editNameId = 0" ref="inputName" @pressEnter="updateColumn(record.id, 'name', record.name); editNameId = 0"
                     v-model:value="record.name"></a-input>
          </div>
          <div class="col-md-2 text-center row">
            <a-button
              @click="updateColumn(record.id, 'name', record.name); editNameId = 0" type="link" shape="round"
              class="px-2">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-md-10">
            <div @click="onEditNameId(record.id)" class="pull-left" :style="{ lineHeight: '30px' }">{{ record.name }}</div>
          </div>
          <div class="col-md-2 text-center row">
            <a-button @click="onEditNameId(record.id)" type="text" shape="round" class="px-2 pull-right">
              <template #icon>
                <EditOutlined/>
              </template>
            </a-button>
          </div>
        </div>
      </template>
      <template #user="{ record }">
        {{ formattedPhone(record.user?.phone) }}
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author?.username">
          ({{ record.author.username }})
        </span>
      </template>
      <template #status="{ record }">
        <a-tag
          v-if="record['status']"
          color="success"
          class="cursor-pointer"
          @click="updateColumn(record.id, 'status', 0)"
        >
          <template #icon>
            <CheckCircleOutlined />
          </template>
          Активный
        </a-tag>
        <a-tag
          v-else
          color="error"
          class="cursor-pointer"
          @click="updateColumn(record.id, 'status', 1)"
        >
          <template #icon>
            <MinusCircleOutlined />
          </template>
          Неактивный
        </a-tag>
      </template>
      <template #updated="{ record }">
        <span v-if="record['updated_on']">
          {{ formattedDatetime(record['updated_on']) }}
        </span>
        <span v-if="record.updated?.username">
          ({{ record.updated?.username }})
        </span>
      </template>
      <template #action="{ record }">
        <div class="w-100 text-right">
          <a-tooltip placement="top" class="mx-2">
            <template #title>Удалить</template>
            <a @click="deleteModal(record['id'])"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 d-flex justify-content-between">
      <p><b>Количество:</b> {{ total }}</p>
      <a-pagination
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
    <a-modal
      :title="`Номер зала:`"
      :visible="hallModal"
    >
      <template #footer>
        <a-button key="back" @click="() => {}">Закрыть</a-button>
      </template>
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Продукт</th>
          <th>Количество</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in orderItems" :key="key">
            <td>
              <div class="order-image" :style="{ backgroundImage: `url(${item.item['imageUrl']})` }">
              </div>
              <span class="order-name">{{ item.item.name }}</span>
            </td>
            <td><span class="order-count">{{ item.count }}</span></td>
          </tr>
        </tbody>
      </table>
    </a-modal>
  </div>
</template>

<script setup>
import {CheckCircleOutlined, EditOutlined, MinusCircleOutlined, SaveOutlined} from '@ant-design/icons-vue';
import {nextTick, onMounted, reactive, ref, toRefs, watch} from 'vue';
import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
import VbCreateHall from "@/views/store/menu/hall/create.vue";
import { message, Modal } from 'ant-design-vue'
import apiClient from "@/services/axios";
import { mask } from "maska";

let editNameId = ref(0),
  stores = ref([]),
  selectedStore = ref('null'),
  hallModal = ref(false),
  orderItems = ref([]),
  dataSource = ref([]),
  loading = ref(false),
  total = ref(0);

const router = useRouter(),
  inputName = ref('inputName'),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    name: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'Номер зала',
      dataIndex: 'hall_id',
      key: 'hall_id',
      width: 150,
      fixed: 'left',
      slots: { customRender: 'hall_id' },
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      slots: { customRender: 'name' },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      slots: { customRender: 'status' },
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      key: 'added_on',
      slots: { customRender: 'date' },
    },
    {
      title: 'Редактирован',
      dataIndex: 'updated_on',
      key: 'updated_on',
      slots: { customRender: 'updated' },
    },
    {
      key: 'action',
      fixed: 'right',
      width: 50,
      slots: { customRender: 'action' },
    },
  ],
  formattedDatetime = (date) => {
    date = date.replace(/ /g,"T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  formattedPhone = (phone) => {
    return (phone) ? mask(phone, '+998(##) ###-##-##') : ''
  },
  onEditNameId = (id) => {
    editNameId.value = id;
    nextTick(() => inputName.value?.focus());
  },
  updateColumn = (id, column, value) => {
    let data = {};
    data[column] = value;
    apiClient.put(`/hall/update?hall=${id}`, data).then(response => {
      if (response) {
        message.success('Зал успешно обновлен')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  deleteModal = id => {
    Modal.confirm({
      title: 'Удаление зала',
      content: `Вы действительно хотите удалить зал?`,
      okText: 'Да',
      cancelText: 'Нет',
      onOk() {
        return apiClient.delete(`hall/delete?hall=${id}`).then(response => {
          if (response) {
            message.success('Зал успешно удален')
          }
        }).catch(() => {
          // console.log(err)
        }).finally(() => {
          reload()
        });
      },
    });
  },
  updateStore = (store) => {
    router.replace({
      name: 'hall',
      params: { store }
    })
  },
  getData = async (store, params) => {
    loading.value = true
    if (store === 'null') {
      await apiClient.get('/hall/index?store=null', { params }).then(({ data }) => {
        store = data.data.stores.find(store => store.value !== 'null')?.value
        selectedStore.value = store
        router.replace({
          name: 'hall',
          params: { store }
        })
      });
    }
    return apiClient.get(`/hall/index?store=${store}`, { params }).then(({data}) => {
      dataSource.value = data.data.items
      total.value = data.data.total
      stores.value = data.data.stores
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData(route.params.store, {
      ...queryParams,
    })
  };

onBeforeRouteUpdate((to, from, next) => {
  getData(to.params.store, {
    ...queryParams,
  })
  next()
})
onMounted(() => {
  getData(route.params.store, {
    ...queryParams,
  })
})

watch(queryParams, () => {
  router.push({
    name: 'hall',
    params: {
      store: route.params.store,
    },
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData(route.params.store, {
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>
<style>
.order-image {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
}
.order-name {
  display: block;
  float: left;
  line-height: 50px;
}
.order-count {
  line-height: 50px;
}
.cursor-pointer {
  cursor: pointer;
}
.save-button {
  width: 32px;
  height: 32px;
}
</style>
