<template>
  <a-button
    class="add-table pull-right ml-3"
    type="primary"
    @click="openModal"
  >
    Добавить зал
  </a-button>
  <a-modal
    class="table-create-modal"
    title="Добавить зал"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="create"
    @cancel="resetForm"
  >
    <a-form :model="hall">
      <div class="col-md-12">
        <a-form-item label="Название зала">
          <a-input
            ref="hallName"
            placeholder="Введите название зала"
            v-model:value="hall.name"
          />
        </a-form-item>
        <a-form-item label="ID зала">
          <a-input
            placeholder="Введите ID зала"
            v-model:value="hall.hall_id"
          />
        </a-form-item>
        <div class="row">
          <div class="col-md-2">
            <a-form-item label="Статус">
              <a-switch
                checked-children="Вкл"
                un-checked-children="Выкл"
                v-model:checked="hall.status" />
            </a-form-item>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import { ref, reactive, toRaw, nextTick } from 'vue';
import apiClient from "@/services/axios";
import { useRoute } from "vue-router";
import Hall from "@/models/Hall";

const emit = defineEmits(['reload']);

let imageUrl = ref(''),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  hallName = ref("hallName"),
  initialHall = new Hall({}),
  hall = reactive(initialHall);

const
  route = useRoute(),
  openModal = () => {
    modalVisible.value = true;
    nextTick(() => hallName.value?.focus());
  },
  resetForm = () => {
    Object.assign(hall, new Hall({}));
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;

    let model = toRaw(hall);
    model.status = model.status ? 1 : 0
    model.store_id = route.params.store

    return apiClient.post('/hall/create', model).then(function (response) {
      if (response) {
        resetForm()
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      emit('reload')
    })
  }
</script>

<style scoped>
</style>
